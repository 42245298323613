import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactPlayer from "react-player";

import VideoPauseBlack from "@/assets/icons/pause-black.svg";
import VideoPlayBlack from "@/assets/icons/play-black.svg";
import VideoMuteWhite from "@/assets/icons/video-mute-white.svg";
import VideoUnMuteWhite from "@/assets/icons/video-unmute-white.svg";

import defaultStyles from "./VideoPlayer.module.scss";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentPlayed: 0,
      posterDisplay: false,
      videoMuted: true,
      videoPlaying: false,
    };

    this.homepageVideoPlayer = React.createRef();
    this.videoProgress = this.videoProgress.bind(this);
    this.videoEnded = this.videoEnded.bind(this);
    this.videoPlayPauseToggle = this.videoPlayPauseToggle.bind(this);
    this.videoMuteToggle = this.videoMuteToggle.bind(this);
    this.interval = null;
  }

  videoPlayPauseToggle() {
    const { videoPlaying } = this.state;
    const { rewindOnPause } = this.props;

    this.setState((state) => {
      return { videoPlaying: !state.videoPlaying };
    });

    const player =
      this.homepageVideoPlayer.current &&
      this.homepageVideoPlayer.current.children[0];

    if (!videoPlaying && rewindOnPause && player) {
      player.seekTo(0);
    }

    if (!player.paused) {
      player.pause();
      clearInterval(this.interval);
      player.firstChild.nodeValue = "Play";
    } else {
      player.play();
      player.firstChild.nodeValue = "Pause";
    }

    // interval of 100ms
    this.interval = setInterval(() => this.videoProgress(), 100);
  }

  videoEnded() {
    const { onVideoEnd } = this.props;
    onVideoEnd && onVideoEnd();
    clearInterval(this.interval);

    this.setState(() => {
      return { percentPlayed: 0, videoPlaying: false };
    });
  }

  videoMuteToggle() {
    this.setState((state) => {
      return {
        videoMuted: !state.videoMuted,
      };
    });
  }

  videoProgress() {
    const player = this.homepageVideoPlayer.current;

    this.setState(() => {
      return {
        percentPlayed: Math.floor(
          (player.getCurrentTime() * 100) / player.getDuration()
        ),
      };
    });
  }

  componentDidMount() {
    const { autoPlay } = this.props;

    if (autoPlay) {
      this.interval = setInterval(() => this.videoProgress(), 100);

      this.setState((state, props) => {
        return { videoPlaying: props.autoPlay };
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      controls,
      tag,
      tertiaryCopy,
      data: {
        videoAsset: { url },
        posterSrc,
      },
      loop,
      styles,
      title,
      subcopy,
      urlRef,
    } = this.props;
    const { videoPlaying, videoMuted, percentPlayed } = this.state;
    const moduleStyles = { ...defaultStyles, ...styles };

    const progressClasses = videoPlaying
      ? `${moduleStyles.videoPlayerProgress} ${moduleStyles.videoPlayerProgressActive}`
      : `${moduleStyles.videoPlayerProgress}`;

    return (
      <>
        <div className={moduleStyles.videoPlayer}>
          <div className={progressClasses}>
            <span style={{ width: `${percentPlayed}%` }} />
          </div>

          <div className={moduleStyles.videoPlayerControls}>
            <div className={moduleStyles.videoPlayerControlsPlayToggle}>
              {controls && (
                <button
                  aria-label={`${videoPlaying ? "Pause" : "Play"} video`}
                  name={`VideoPlayer - ${videoPlaying ? "Pause" : "Play"}`}
                  onClick={this.videoPlayPauseToggle}
                  title={`${videoPlaying ? "Pause" : "Play"} video`}
                >
                  {videoPlaying ? <VideoPauseBlack /> : <VideoPlayBlack />}
                </button>
              )}

              <div className={moduleStyles.videoPlayerControlsTitle}>
                <h2>
                  {tag && (
                    <div>
                      <span>{tag}</span>
                    </div>
                  )}

                  {tertiaryCopy && (
                    <div>
                      <span>{tertiaryCopy}</span>
                    </div>
                  )}
                </h2>
              </div>
            </div>

            <div className={moduleStyles.videoPlayerControlsMuteToggle}>
              {controls && (
                <button
                  aria-label={`${videoMuted ? "Unmute" : "Mute"} video`}
                  name={`VideoPlayer - ${videoMuted ? "Unmute" : "Mute"}`}
                  onClick={this.videoMuteToggle}
                  title={`${videoMuted ? "Unmute" : "Mute"} video`}
                >
                  {videoMuted ? <VideoMuteWhite /> : <VideoUnMuteWhite />}
                </button>
              )}
            </div>
          </div>

          {posterSrc && (
            <div className={moduleStyles.videoPlayerPoster}>
              <img alt="cover" src={posterSrc} />
            </div>
          )}

          <ReactPlayer
            className={moduleStyles.videoPlayerObject}
            height="100%"
            loop={loop}
            muted={videoMuted}
            playing={videoPlaying}
            playsinline={true}
            ref={this.homepageVideoPlayer}
            url={(url.indexOf("//") === 0 ? "https:" : "") + url}
            width="100%"
          />
        </div>

        {(title || subcopy) && (
          <div>
            <a
              className={moduleStyles.moduleEditorialGridSingleColumnUrlRef}
              href={urlRef}
              rel="noreferrer"
              target={"_blank"}
            >
              {title && (
                <h3
                  className={moduleStyles.moduleEditorialGridSingleColumnTitle}
                >
                  {title}
                </h3>
              )}
              {subcopy && (
                <p
                  className={
                    moduleStyles.moduleEditorialGridSingleColumnSubcopy
                  }
                >
                  {subcopy}
                </p>
              )}
            </a>
          </div>
        )}
      </>
    );
  }
}

export default VideoPlayer;

VideoPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  data: PropTypes.object.isRequired,
  loop: PropTypes.bool,
  onVideoEnd: PropTypes.func,
  rewindOnPause: PropTypes.bool,
  styles: PropTypes.object,
  subcopy: PropTypes.string,
  tag: PropTypes.string,
  tertiaryCopy: PropTypes.string,
  title: PropTypes.string,
  urlRef: PropTypes.string,
};

VideoPlayer.defaultProps = {
  controls: true,
  rewindOnPause: false,
};
